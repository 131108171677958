<template>
  <main>
    <div class="layout-subcontainer survey-container">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-3">
          <h1 class="tit-h1 d-flex align-center type-line"><v-icon class="svg-survey mr-2"></v-icon>설문 내용
            <div class="ml-auto">
              <v-btn outlined small class="btn-default ml-2" @click="fnDefBtnClck">초기화</v-btn>
              <v-btn outlined small class="btn-default ml-2" @click="fnSurveyList">목록</v-btn>
              <v-btn outlined small class="btn-default ml-2" :disabled="cpQutnChkBool" @click="fnCopySurvey">설문복사</v-btn>
              <v-btn outlined small class="btn-point ml-2" :disabled="saveBtnBool" @click="fnEndSave">저장</v-btn>
            </div>
          </h1>
        </div>
        <div class="col-6 pa-3">
          <h1 class="tit-h1 d-flex align-center type-line" v-if="saveBtnBool"><v-icon small class="svg-test-check mr-2"></v-icon>설문 미리보기
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2" @click="fnSurveyPreviewBtn" v-if="previewDefBool">미리보기</v-btn>
            </div>
          </h1>
        </div>
      </div>
      <div class="box-wrap d-flex">
        <div class="col-6 pa-3 scrollable">
          <!-- <h1 class="tit-h1 d-flex align-center type-line"><v-icon class="svg-survey mr-2"></v-icon>설문 내용</h1> -->
          <div style="height: 74.5vh">
            <div>
              <div class="box-line">
                <div class="form-unit">
                  <div class="in-label">설문 제목</div>
                  <div class="in-value">
                    <v-textarea
                      class="textarea-type0"
                      style="width:33vw;"
                      v-model="SURVEY_TITLE"
                      rows="3"
                      no-resize
                      outlined
                      name="surveyTitle"
                    ></v-textarea>
                  </div>
                </div>
                <div class="form-unit">
                  <div class="in-label">설문 내용</div>
                  <div class="in-value">
                    <v-textarea
                      class="textarea-type0"
                      style="width:33vw;"
                      v-model="SURVEY_CONT"
                      rows="7"
                      no-resize
                      outlined
                      name="surveyCont"
                    ></v-textarea>
                  </div>
                </div>
                <div class="form-unit">
                  <div class="in-label">사용여부</div>
                  <div class="in-value">
                    <div class="form-inp full">
                      <v-autocomplete
                        :items="useYnItems"
                        style="width:9vw;"
                        item-value="CD"
                        item-text="CD_NM"
                        v-model="USE_YN"
                        outlined
                        placeholder="선택하세요"
                        name="useYn"
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="btn-add">
                  <v-btn
                    dark small min-width="22px" height="32px" plain class="pa-0 svg-form-add"
                    @click="fnAddTemplate()"
                    >
                  </v-btn>
                </div>
                <div class="btn-fixed">
                  <v-btn
                    v-if="saveBtnBool"
                    outlined small class="btn-point ml-2" 
                    style="margin-right: 7px;"
                    @click="fnTopSaveTempConf"
                  >제목수정</v-btn>
                </div>
              </div>
              <!-- 추가질문 템플릿 -->
              <div class="box-line"
                v-for="(item, idx) in TEMPLATE_LIST"
                :key="idx"
              >
                <div class="surveyItem">
                  <!-- 버튼 -->
                  <div class="btn-fixed">
                    <v-btn
                      v-if="saveBtnBool"
                      outlined small class="btn-point ml-2" 
                      style="margin-right: 7px;"
                      @click="fnSaveTemplatte(item)"
                    >문항수정</v-btn>
                    <v-btn
                      outlined small class="btn-etc"
                      @click="fnRemoveTemplatte(item)"
                    >삭제</v-btn>
                  </div>
                  <div class="form-unit">
                    <div class="in-label">질문 순서</div>
                    <div class="in-value">
                      <v-text-field
                        class="form-inp full pt-0"
                        outlined 
                        style="width:5vw;"
                        type="number"
                        min="0"
                        max="77"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                        v-model.number.lazy="ORDER_SEQ_ARR[idx]"
                        :name="'orderSeq_'+idx" 
                      ></v-text-field>
                    </div>
                  </div>
                  <!-- 유형 -->
                  <div class="form-unit">
                    <div class="in-label">유형</div>
                    <div class="in-value">
                      <select 
                        :name="'leaveType_'+idx" 
                        @change="fnTypeByChange($event)" 
                        class="select input" 
                        :id="'type_'+idx"
                        v-model="TYPE_ARR[idx]"
                        style="width:33vw; background-color: white; border-style: ridge; border-color: #949494; font-style: normal; color: #6C6E7A !important; font-size:12px !important; padding:0 10px !important; min-height: 32px !important; height: 32px; ">
                        <option value="">선택하세요</option>
                        <option 
                          v-for="(typeItem, typeIdx) in typeItems" 
                          :key="typeItem.CD"
                          :value="typeItem.CD"
                        >
                          {{typeItem.CD_NM}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-unit" v-if="MULTI_TYPE_LIMIT_BOOL[idx]">
                    <div class="in-label">개수제한</div>
                    <div class="in-value">
                      <v-text-field
                        class="form-inp full pt-0"
                        outlined 
                        style="width:4vw;"
                        name="MULTI_TYPE_LIMIT"
                        type="number"
                        min="0"
                        max="77"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                        v-model.number.lazy="MULTI_TYPE_LIMIT[idx]"
                      ></v-text-field>
                    </div>
                  </div>
                  <!-- 질문 -->
                  <div class="form-unit type-add surveyAddDiv">
                    <div class="in-label">질문</div>
                    <!-- 이미지 미리보기 없을때 -->
                    <div class="in-value" :id="'surveyDiv_'+idx">
                      <div class="d-flex">
                        <v-text-field
                          class="form-inp full pt-0"
                          :id="'quesOne_'+idx"
                          style="margin-bottom:17px; width:33vw;"
                          v-model="QUES_ONE[idx]"
                          outlined>
                        </v-text-field>
                      </div>
                      <div class="d-flex">
                        <v-textarea
                          class="textarea-type0"
                          v-model="QUES_TWO[idx]"
                          :id="'quesTwo_'+idx"
                          no-resize
                          outlined
                        ></v-textarea>
                      </div>
                    </div>
                  </div>
                  <!-- 답변 -->
                  <div class="in-label">답변<span class="add-icon svg-survey-arrow"></span></div>
                  <div class="btn-select-add mt-2">
                    <h3 :id="'typeByText_'+idx" style="display:none"></h3>
                    <v-btn outlined small rounded 
                      class="btn-default" 
                      @click="fnAnswerAdd(item, idx)" 
                      :name="'typeByTextBtnName_'+idx"
                      :id="'typeByTextBtn_'+idx"
                    >
                      + 보기추가
                    </v-btn>
                  </div>
                  <div class="form-unit">
                    <div class="in-value" :id="'answDivCla_'+idx">
                      <v-text-field
                        v-for="(child, chIdx) in item.ANSWER_LIST"
                        :key="chIdx"
                        class="form-inp full type-line"
                        :id="'answerText_'+idx+'_'+chIdx"
                        :name="'answerTextName_'+idx+'_'+chIdx"
                        v-model="ANSWER_PA_TEXT[idx].ANSWER_CH_TEXT[chIdx]"
                        style="width:34vw;"
                        outlined>
                        <template v-slot:append>
                          <v-btn
                            depressed
                            icon
                            x-small
                            class="fomr-inp--btn svg-close20"
                            :id="'answerBtn_'+idx+'_'+chIdx"
                            @click="fnAnswerRemove(idx, chIdx)">
                          </v-btn>
                        </template>
                      </v-text-field>
                    </div>
                  </div>
                  <div class="btn-add">
                    <v-btn
                      dark small min-width="22px" height="32px" plain class="pa-0 svg-form-add"
                      @click="fnAddTemplate()"
                    >
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pa-3 scrollable" v-if="saveBtnBool">
          <div class="mo-reserve-wrap" style="height: 73.5vh; padding: 15px 220px 15px 220px; !important">
            <div style="padding:30px">
              <Survey 
                ref="Survey"
                :PAGE_MODE="this.PAGE_MODE" 
                :PAGE_DUB_MODE="this.PAGE_DUB_MODE" 
              >
              </Survey>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-if="dialogCAP0200" max-width="1500" max-height="1000" content-class="square-modal min-auto" persistent v-model="dialogCAP0200">
      <dialog-CAP0200
          headerTitle="설문 목록"
          :param="CAP0200Param"
          :PART_TYPE="PART_TYPE"
          p01
          @hide="hideDialog('CAP0200')"
          @submit="submitDialog('CAP0200')"
      >
      </dialog-CAP0200>
    </v-dialog>
  </main>
</template>

<script>
  import DialogCAP0200 from "./CAP0200.vue" 
  import Survey from "@/components/Survey";

  export default {
    name: "MENU_CAM0200", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCAP0200
      , Survey
    },
    data() {
      return {
        CAP0200Param:{},
        PART_TYPE: 'PO',  //분양
        modiBoolArr:[false],
        previewDefBool:false,
        saveBtnBool:false,
        dialogCAP0200:false,
        defImgSrc:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAABZCAYAAABVC4ivAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAN0SURBVHgB7Z2/SyNRFIVvYgrFZivBQokWFmKzlbX2srETLHZBsHTXUhB2BcFStBQES8v8BVpbbaONhcYUClYWihaKO2fwQTKbH8ZMzpsZzwdBHWUiX27Ou++9wMtZwPHx8Zfn5+dfuVzu++vra9FEVwQeK4HHcqFQ2JiZmbnLQfDLy8vfkZGR4ujoqPX395vojqenJ7u4uLCbm5tKX1/f10Ig+M/w8HBxYmLCRDygUCcnJy2o5GK1Wv2ZD659Gx8fNxE/QTpYEBs/8shgRURvGBgYQD4X8yZ6jiQTkGQCkkxAkglIMgFJJiDJBCSZgCQTkGQCkkxAkglIMgFJJiDJBCSZgCQTkGQCkkwgs5KPjo5scXHRlpeXw+99klnJh4eH9vDwYLe3t7a/v28+yaxkyHVAtk+UyQQkmYAkEygYEeTk7u6uXV5edp2Ta2trNj09HXYOGNja3a9UKtX9PDQ0ZAsLCzY7O2u9hlbJELy6umqnp6exDEQQDFwX8ZH/h9V10CS/p9o6wd2rm3sODg4aA5rkk5MTixNEDlhaWgrf+p0CwYgLBtRMjhNk+/b2dpipjXI1msHlctl8kdruojbjk05qKxlA9Pr6et01nxXbDPXJBDIn2XUbtZ3DRwbGOMmc5GjXwewimpHqTG5Eu67DB5mrZNd1uL4c8eG7A8lcJQOI3traqrumPjnjSDIBSSYgyQQkmUAqJU9NTdnY2JilhdRJxiwOW0+bm5upEZ0oyZgCt1pnwO8hF1/xgGzf6xLvITGSIWtvby+cEjfbFsIaRK1UV9VJJxGSoxW6srLy39/Mzc2FjyiIDCwGJZlESI5WKHai3W40cNv3zWj2AiQF75KbCUI1u8p2Vd4KVHNSB0KvkltVqIuNaJW3IqkDoVfJ7SoUkdFJDEBwozz3TWa3n5IEbT0ZFRsVgE/BfwZolexzUKrtVHxAk+y6BTZ4Tt99NE0yBiXM5lhVBblYSMJz+u44qHt8aZkGx43WkwlIMgFJJiDJBCSZgCQTkGQCkkxAkglIMgFJJiDJBCSZgCQTkGQCkkxAkglIMgFJJiDJBCSZQB6HpuJMTxE/j4+POFmyAskHV1dXJuIHh9EGfsv5gJ1qtVo5OzsLzYvuQTKcn5/b9fV15f7+fiOHi2/nVv8OSruEMz1NdMXbudUHgeCd+fn5u39cWS7+I+fCPAAAAABJRU5ErkJggg==',
        IMG_MODE: false, //이미지 미리보기상태, false: 없음, true: 있음
        typeItems:[],
        typeCode:['HLW_QUTN_ITEM_TYPE_CD'],
        useYnItems:[
          {CD:'Y',CD_NM:'사용'},
          {CD:'N',CD_NM:'미사용'}
        ],
        andsAddBtnBool:true,
        surveyItemBool:false,
        PAGE_MODE:'M',
        PAGE_DUB_MODE:'W',
        SURVEY_AF_DATAS: [],

        // V-MODEL
        QUTN_ID:'',
        QUTN_ITEM_CODE:[],
        QUTN_ITEM_DETL_CODE:[],
        QUTN_ITEM_DETL_CODE_ARR:[],
        ORDER_SEQ_ARR:[1],
        BUSS_CODE:'',
        BUSS_NAME:'',
        CMP_ID:'',
        CMP_NAME:'',
        SURVEY_TITLE:'',
        SURVEY_CONT:'',
        USE_YN:'Y',
        VM_TYPE_ITEMS:[],
        VM_QUES_ITEMS:[],
        VM_QUES_IMG_ITEMS:[],
        VM_QUES_TEXT_KEY:[],
        VM_QUES_IMG_ITEMS_OBJ:[],
        VM_PARENT_QUES_ANS_TEXT_ITEMS:[],
        VM_CHILD_QUES_ANS_TEXT_ITEMS:[],

        ITEM_CODE_ARR:[{
          ITEM_DETA_CODE_ARR:[]
        }],
        
        TYPE_ARR:[''],
        QUES_ONE:[],
        QUES_TWO:[],
        IMG_INPUT_MODEL:[],
        IMG_SRC_MODEL:[],
        IMG_KEY_MODEL:[],
        ANSWER_PA_TEXT:[
          {
            ANSWER_CH_TEXT:[]
          }
        ],

        MULTI_TYPE_LIMIT:[],
        MULTI_TYPE_LIMIT_BOOL:[false],
        TOT_DATA_ITEMS:[],

        ansInputItems:[],
        TEMPLATE_LIST:[
          {
            ANSWER_LIST: []
          },
        ],

        alertMsg:{
          typeSelMsg:'유형을 선택해주세요.',
          previewChkMsg:'질문을 다시 불러와주세요.',
          saveMsg:'저장하시겠습니까?',
          uptMsg:'수정하시겠습니까?',
          saveSuccMsg:'저장됐습니다',
          error:'관리자에게 문의해주세요.',
          surveyCopyChk:'설문을 복사하시겠습니까?',
          procSurveyCopy:'설문을 복사했습니다.',
          surveyListEmpty:'목록에서 설문을 선택해주세요.',
          surveyCleare:'설문을 초기화하시겠습니까?\n(데이터가 삭제되지 않습니다.)',
          qutnItemSaveChkMsg:'해당 질문을 저장하시겠습니까?',
          itemDelChkMsg:'해당 질문을 삭제하시겠습니까?',
          ansChkMsg:'보기를 등록해주세요.',
          answDelChkMsg:'해당 보기를 삭제하시겠습니까?',
          surveyTitleChkMsg:'설문 제목을 입력해주세요.',
          surveyUseYnChkMsg:'설문 사용 여부를 선택해주세요.',
        },

        updBool:false,

        baseUrl:process.env.VUE_APP_API_URL,

        qutnId:this.$store.getters['commonStore/GE_COMMON_SURVEY_ITEM'],

        allCodeList: [],
      }
    },

    async mounted() {
      this.allCodeList=await this.mixin_common_code_get_all(this.typeCode)
      this.typeItems=await this.mixin_common_code_get(this.allCodeList, this.typeCode[0], '')

      await this.fnInit();
    },

    methods: {
      
      fnInit() {
        if(!this.mixin_isEmpty(this.qutnId)) {
          this.QUTN_ID=this.qutnId;
          this.fnInitQutnData();
          this.updBool=true;
        }
        if(!this.mixin_isEmpty(document.getElementsByName('leaveType_0')[0])) {
          setTimeout(() => document.getElementsByName('leaveType_0')[0].focus(), 111);
        }
      },

      async fnInitQutnData() {
        let requestData = {
          headers: {
            METHOD: 'list',
          },
          sendData:{}
        };

        requestData.headers["URL"] = "/api/hlw/advanReserv/campaign/manage/survey/item";

        // sendData 세팅
        requestData.sendData["QUTN_ID"] = this.QUTN_ID;

        let responseGridList = await this.common_postCall(requestData, false);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        } else {
          const resObj={
            qutnDatas:resData
          };
  
          resData=[resObj];
          this.fnQutnItemSett(resData);
        }
      },

      fnCopySurvey() {
        if(this.mixin_isEmpty(this.QUTN_ID)) {
          this.common_alert(this.alertMsg.surveyListEmpty, 'chk');
          return;
        }
        this.common_confirm(this.alertMsg.surveyCopyChk, this.fnSurveyCopyProc, null, null, null, 'chk');
      },

      fnSurveyCopyProc() {
        this.updBool=false; //업데이트 상태 변경
        this.QUTN_ID=''; //설문 복사시 셋팅된 id 초기화
        this.saveBtnBool=false;
        this.common_alert(this.alertMsg.procSurveyCopy, 'chk');
        this.previewDefBool=false;
      },

      fnSurveyList() {
        this.CAP0200Param={
          headerTitle: "설문 목록",
          data:[],
          type:"I",
        };

        this.showDialog('CAP0200');
      },

      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },
      
      submitDialog(type) {
        this.fnItemDef('');
        const items=this.CAP0200Param.data;
        this.fnQutnItemSett(items);
        this.hideDialog(type);
      },

      fnQutnItemSett(items) {
        let TEMPLATE_LIST=[];
        let ANSWER_LIST=[];
        let ANSWER_PA_TEXT=[];
        this.QUTN_ITEM_CODE=[];
        this.QUTN_ITEM_DETL_CODE=[];
        this.TYPE_ARR=[];
        let twoDatas=[];
        this.QUTN_ITEM_DETL_CODE_ARR=[];

        items.forEach((one, i) => {
          const qutnDatas=one.qutnDatas;
          const item=qutnDatas[i];

          this.SURVEY_TITLE=item.QUTN_NAME;
          if(this.SURVEY_TITLE.includes('br')) {
            this.SURVEY_TITLE=this.SURVEY_TITLE.replace(/(<br>|<br\/>|<br \/>)/g, '\n');
          }

          this.SURVEY_CONT=item.QUTN_CONT;

          if(this.SURVEY_CONT.includes('br')) {
            this.SURVEY_CONT=this.SURVEY_CONT.replace(/(<br>|<br\/>|<br \/>)/g, '\n');
          }

          this.USE_YN=item.USE_YN;
          this.QUTN_ID=item.QUTN_ID;
          // 설문 ID가 존재하면 업데이트 상태로 변경
          if(!this.mixin_isEmpty(this.QUTN_ID)) {
            this.updBool=true;
            this.saveBtnBool=true;
            this.previewDefBool=false;
          }
          this.BUSS_CODE=item.BUSS_CODE;
          this.BUSS_NAME=item.BUSS_NAME;
          this.CMP_ID=item.CMP_ID;
          this.CMP_NAME=item.CMP_NAME;

          this.ORDER_SEQ_ARR=[];
          if(!this.mixin_isEmpty(item.QUTN_ITEMS)) {
            const itemChd=item.QUTN_ITEMS;
            itemChd.forEach((two, k) => {
              ANSWER_LIST=[];

              if(!this.mixin_isEmpty(two.QUTN_ITEM_TYPE_CD)) {
                this.modiBoolArr[k]=true;
              }else{
                this.modiBoolArr[k]=false;
              }
              this.ORDER_SEQ_ARR.push(two.ORDER_SEQ);
              this.QUTN_ITEM_CODE.push(two.QUTN_ITEM_CODE);
              this.TYPE_ARR.push(two.QUTN_ITEM_TYPE_CD);

              if(two.QUTN_ITEM_TYPE_CD==='QUTN_ITEM_TYPE_CD_11') {
                this.MULTI_TYPE_LIMIT_BOOL[k]=true;
                this.MULTI_TYPE_LIMIT[k]=two.MULTI_TYPE_LIMIT;
              }

              const itemChdChds=two.QUTN_DETA_ITEMS;
              let ANSWER_CH_TEXT=[];
              this.QUTN_ITEM_DETL_CODE=[];
              itemChdChds.forEach((the, j) => {
                this.QUTN_ITEM_DETL_CODE.push(the.QUTN_ITEM_DETL_CODE);
                ANSWER_CH_TEXT.push(the.QUTN_ITEM_DETL_CONT);
                ANSWER_LIST.push('');
              })
              this.QUTN_ITEM_DETL_CODE_ARR.push(this.QUTN_ITEM_DETL_CODE);

              ANSWER_PA_TEXT.push({ANSWER_CH_TEXT:ANSWER_CH_TEXT});
              
              TEMPLATE_LIST.push({ANSWER_LIST:ANSWER_LIST});

              this.TEMPLATE_LIST=TEMPLATE_LIST;

              twoDatas.push(two);

            });
          }
          
          this.ANSWER_PA_TEXT=ANSWER_PA_TEXT;  
        });

        setTimeout(() => this.fnTypeByContSett(twoDatas), 555);
      },

      fnTypeByContSett(twoDatas) {
        twoDatas.forEach((two, k)=> {
          if(two.QUTN_ITEM_IMG_KEY){

            this.QUES_ONE.push("");
            this.QUES_TWO.push("");

            if(!this.mixin_isEmpty(document.querySelector('#surveyDiv_'+k))) {
              document.querySelector('#surveyDiv_'+k).style.display='none';
            }

          }else{
            this.QUES_ONE.push(two.QUTN_ITEM_NAME);

            if(two.QUTN_ITEM_CONT.includes('br')) {
              let replVal = two.QUTN_ITEM_CONT.replace(/(<br>|<br\/>|<br \/>)/g, '\n');
              this.QUES_TWO.push(replVal);
            }else{
              this.QUES_TWO.push(two.QUTN_ITEM_CONT);
            }
          }

          this.fnTextOnOff(two.QUTN_ITEM_TYPE_CD, k);
        });

        this.fnSurveyPreviewBtn();
      },

      fileExtTypeChk(fileName){
        let boolParam=false;
        for (let index = 0; index < this.geImgExtArry.length; index++) {
          if(fileName.indexOf(this.geImgExtArry[index])!==-1){
            boolParam=true;
          }
        }
        return boolParam;
      },

      fnEndSave() {
        const surveyLng=this.TEMPLATE_LIST.length;

        if(this.mixin_isEmpty(this.SURVEY_TITLE)) {
          this.common_alert(this.alertMsg.surveyTitleChkMsg, 'chk');
          document.getElementsByName('surveyTitle')[0].focus();
          return;
        }else if(this.mixin_isEmpty(this.USE_YN)) {
          this.common_alert(this.alertMsg.surveyUseYnChkMsgm, 'chk');
          document.getElementsByName('useYn')[0].focus();
          return;
        }

        //질문 유형별 전송 데이터 아이템들 초기화
        this.VM_TYPE_ITEMS=[]; //유형별 아이템 목록
        this.VM_QUES_ITEMS=[]; //질문 아이템 목록
        this.VM_PARENT_QUES_ANS_TEXT_ITEMS=[]; //설문별 질문 묶음 목록
        this.VM_QUES_IMG_ITEMS=[]; //이미지 객체 아이템 목록
        this.VM_QUES_IMG_ITEMS_OBJ=[]; //이미지명 아이템 목록
        this.VM_QUES_TEXT_KEY=[]; //질문 텍스트 INDEX

        for (let index = 0; index < surveyLng; index++) {
          //유형 items
          const typeEle=document.querySelector('#type_'+index);
          const typeValue=typeEle.value;

          if(this.mixin_isEmpty(typeValue)) {
            this.common_alert(this.alertMsg.typeSelMsg, 'chk');
            document.getElementsByName('leaveType_'+index)[0].focus();
            return;
          }else{
            this.VM_TYPE_ITEMS.push(typeValue);
          }

          //수정 및 설문복사시 넘어오는 이미지 key
          // const imgHiddValue=document.getElementById('imageHiddenId_'+index)

          //질문 one items
          let quesEle=document.querySelector('#quesOne_'+index);
          let quesValue=quesEle.value;

          //질문 two items
          quesEle=document.querySelector('#quesTwo_'+index);

          let twoVal=quesEle.value;
          if(twoVal.includes('\n')) {
            twoVal=twoVal.replace(/\n/ig, '<br/>');
          }
          quesValue+='&'+twoVal;

          this.VM_QUES_ITEMS.push(quesValue);
          this.VM_QUES_TEXT_KEY.push(index);

          //내부 답변 sett
          if(typeEle.value==="QUTN_ITEM_TYPE_CD_10"||typeEle.value==="QUTN_ITEM_TYPE_CD_11"||typeEle.value==='QUTN_ITEM_TYPE_CD_12') {

            //질문 유형별 답변 목록 초기화
            this.VM_CHILD_QUES_ANS_TEXT_ITEMS=[];
            const ansLng=this.TEMPLATE_LIST[index].ANSWER_LIST.length;

            if(this.mixin_isEmpty(this.ANSWER_PA_TEXT[index].ANSWER_CH_TEXT) && ansLng==0) {
              this.common_alert(this.alertMsg.ansChkMsg, 'chk');
              document.getElementsByName('typeByTextBtnName_'+index)[0].focus();
              return;
            }else if(ansLng>0){
              for (let k = 0; k < ansLng; k++) {
                const element = this.ANSWER_PA_TEXT[index].ANSWER_CH_TEXT[k];

                if(this.mixin_isEmpty(element)) {
                  this.common_alert(this.alertMsg.ansChkMsg, 'chk');
                  document.getElementsByName('answerTextName_'+index+'_'+k)[0].focus();
                  return;
                }
              }
            }

            for (let key = 0; key < ansLng; key++) {
              const id=document.getElementById('answerText_'+index+'_'+key);
              const ansTextValue = id.value;
              this.VM_CHILD_QUES_ANS_TEXT_ITEMS.push(ansTextValue); //질문별 답변 목록
            }
            this.VM_PARENT_QUES_ANS_TEXT_ITEMS.push(this.VM_CHILD_QUES_ANS_TEXT_ITEMS); //질문 답변 목록 묶음
          }
        }

        this.common_confirm(this.alertMsg.saveMsg, this.fnRegistDatas, null, null, null, 'chk');
      },

      fnRegistDatas() {
        //질문 유형별 묶음
        const surveyLng=this.TEMPLATE_LIST.length;

        let quId='';
        if(this.updBool){
          quId=this.QUTN_ID;
        }

        let surveyTitle=this.SURVEY_TITLE;
        if(this.SURVEY_TITLE.includes('\n')) {
          surveyTitle=this.SURVEY_TITLE.replace(/\n/ig, '<br/>');
        }

        let surveyCont=this.SURVEY_CONT;
        if(this.SURVEY_CONT.includes('\n')) {
          surveyCont=this.SURVEY_CONT.replace(/\n/ig, '<br/>');
        }

        let objQutn={
          QUTN_ID:quId,
          SURVEY_TITLE:surveyTitle,
          SURVEY_CONT:surveyCont,
          USE_YN:this.USE_YN,
          USER_ID:this.user_id,
          TOT_DATAS:[]
        };

        let cnt=0;
        for (let index = 0; index < surveyLng; index++) {
          let obj={
            QUTN_ITEM_TYPE_CD:this.VM_TYPE_ITEMS[index], //설문 항목 유형 코드
            QUTN_ITEM_NAME_QUTN_ITEM_CONT:this.VM_QUES_ITEMS[index], //유형별 질문 아이템
          };

          if(obj.QUTN_ITEM_TYPE_CD==="QUTN_ITEM_TYPE_CD_10"
            ||obj.QUTN_ITEM_TYPE_CD==="QUTN_ITEM_TYPE_CD_11"
              ||obj.QUTN_ITEM_TYPE_CD==='QUTN_ITEM_TYPE_CD_12'
          ){
            obj['QUTN_ITEM_DETL_CONT']=this.VM_PARENT_QUES_ANS_TEXT_ITEMS[cnt];
            cnt++;

            if(obj.QUTN_ITEM_TYPE_CD==="QUTN_ITEM_TYPE_CD_11") {
              obj['MULTI_TYPE_LIMIT']=this.MULTI_TYPE_LIMIT[index];
            }
          }
          objQutn.TOT_DATAS.push(obj);
        }

        const alertErr=this.common_alert(this.alertMsg.error, "error");
        const succAlert=this.common_alert(this.alertMsg.saveSuccMsg);

        const defFn=this.fnItemDef('SAVE');

        this.updBool=false; //업데이트 상태 변경
        let retQutnId='';

        $.ajax({
          url: this.baseUrl+"api/hlw/campaign/manage/survey/regist",
          type: "post",
          data: JSON.stringify(objQutn),
          dataType: "json",
          contentType: "application/json",
          headers : {
            Authorization:this.mixin_getToken()
          },
          success: function(data) {
            if (!data) {
              return alertErr;
            }else{
              retQutnId=data;
              return defFn;
            }
          },
          error: function(errorThrown) {
            return alertErr;
          }
        });

        setTimeout(() => { this.QUTN_ID=retQutnId }, 333);
      },

      fnSurveyPreviewBtn() {
        if(this.mixin_isEmpty(this.QUTN_ID)) {
          this.common_alert(this.alertMsg.previewChkMsg, 'chk');
        }else{
          let datas={
            QUTN_ID:this.QUTN_ID
            , ANS_BOOL:false, //답변 조회 여부
          };
          this.$refs.Survey.fnSurveyPreview(datas);
        }
      },

      fnDefBtnClck() {
        this.common_confirm(this.alertMsg.surveyCleare, this.fnItemDef, null, null, null, 'chk');
      },

      fnItemDef(type) {
        this.QUTN_ID='';
        this.SURVEY_TITLE='';
        this.SURVEY_CONT='';
        this.USE_YN='Y';
        this.TEMPLATE_LIST=[{
          ANSWER_LIST:[]
        }];
        this.ORDER_SEQ_ARR=[1];
        this.TYPE_ARR=[''];
        this.MULTI_TYPE_LIMIT=[];
        this.MULTI_TYPE_LIMIT_BOOL=[];
        this.QUES_ONE=[];
        this.QUES_TWO=[];
        this.ANSWER_LIST=[] ;
        this.ANSWER_PA_TEXT=[{
          ANSWER_CH_TEXT:[]
        }];
        this.saveBtnBool=false;
        this.previewDefBool=false;

        const typeByTextEle=document.getElementById('typeByText_0');
        const typeByTextBtnEle=document.getElementById('typeByTextBtn_0');

        if(!this.mixin_isEmpty(typeByTextEle)) {
          typeByTextEle.style.display='none';
        }
        if(!this.mixin_isEmpty(typeByTextBtnEle)) {
          typeByTextBtnEle.style.display='block';
        }

        if(type=='SAVE') {
          this.common_alert(this.alertMsg.saveSuccMsg);
        }

        this.modiBoolArr=[false];
      },

      callNo() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },
      
      fnTypeByChange(event) {
        const element=event.currentTarget;
        const eleIdLng=element.id.split('_');
        const idx=eleIdLng[eleIdLng.length-1];
        let val=element.value;

        this.fnTextOnOff(val, idx);
      },

      fnTextOnOff(val, idx) {
        const typeByTextEle=document.getElementById('typeByText_'+idx);
        const typeByTextBtnEle=document.getElementById('typeByTextBtn_'+idx);
        if(val==='QUTN_ITEM_TYPE_CD_10'){ //라디오
          typeByTextEle.style.display='none';
          typeByTextBtnEle.style.display='block';
          this.MULTI_TYPE_LIMIT_BOOL[idx]=false;
        }else if(val==='QUTN_ITEM_TYPE_CD_11'){ //체크
          typeByTextEle.style.display='none';
          typeByTextBtnEle.style.display='block';
          this.MULTI_TYPE_LIMIT_BOOL[idx]=true;
        }else if(val==='QUTN_ITEM_TYPE_CD_12'){ //셀렉트
          typeByTextEle.style.display='none';
          typeByTextBtnEle.style.display='block';
          this.MULTI_TYPE_LIMIT_BOOL[idx]=false;
        }else if(val==='QUTN_ITEM_TYPE_CD_20'){ //텍스트
          typeByTextBtnEle.style.display='none';
          typeByTextEle.style.display='block';
          typeByTextEle.innerText='텍스트필드';
          this.fnAnsTextClear(idx);
          this.MULTI_TYPE_LIMIT_BOOL[idx]=false;
        }else if(val==='QUTN_ITEM_TYPE_CD_21'){ //파일업로드
          typeByTextBtnEle.style.display='none';
          typeByTextEle.style.display='block';
          typeByTextEle.innerText='파일업로드';
          this.MULTI_TYPE_LIMIT_BOOL[idx]=false;
          this.fnAnsTextClear(idx);
        }else if(val==='QUTN_ITEM_TYPE_CD_30'){ //달력/시간
          typeByTextBtnEle.style.display='none';
          typeByTextEle.style.display='block';
          typeByTextEle.innerText='달력/시간';
          this.MULTI_TYPE_LIMIT_BOOL[idx]=false;
          this.fnAnsTextClear(idx);
        }
      },

      fnAnsTextClear(idx) {
        this.TEMPLATE_LIST[idx].ANSWER_LIST=[];
        this.ANSWER_PA_TEXT[idx]={ANSWER_CH_TEXT:[]};
      },

      fnAddTemplate() {
        const tepleIdx=this.TEMPLATE_LIST.length;

        this.TYPE_ARR[tepleIdx]='';

        this.TEMPLATE_LIST.push({ index: tepleIdx, ANSWER_LIST: [] });
        let ans=this.ANSWER_PA_TEXT;
        ans.push({ANSWER_CH_TEXT:[]});

        this.MULTI_TYPE_LIMIT.push('');
        this.MULTI_TYPE_LIMIT_BOOL.push(false);

        this.QUTN_ITEM_CODE.push('');
        this.QUTN_ITEM_DETL_CODE_ARR.push([]);

        let maxOrderSeq=Math.max(...this.ORDER_SEQ_ARR);
        this.ORDER_SEQ_ARR.push(++maxOrderSeq);

        this.modiBoolArr[tepleIdx]=false;

        setTimeout(() => document.getElementsByName('leaveType_'+tepleIdx)[0].focus(), 555);
      },

      fnRemoveTemplatte(item) {

        const index=this.TEMPLATE_LIST.indexOf(item);
        this.common_confirm(this.alertMsg.itemDelChkMsg, this.fnItemDelete, index, null, null, 'chk');
      },

      fnItemDelete(index) {
        this.modiBoolArr.splice(index, 1)
        if(!this.mixin_isEmpty(this.QUTN_ID)) {
          let objQutn={
            QUTN_ID:this.QUTN_ID,
            QUTN_NAME:this.SURVEY_TITLE,
            QUTN_CONT:this.SURVEY_CONT,
            USE_YN:'N',
            USER_ID:this.user_id,
            QUTN_ITEM_CODE:this.QUTN_ITEM_CODE[index],
          };

          const alertErr=this.common_alert(this.alertMsg.error, "error");
          const defFn=this.common_alert(this.alertMsg.saveSuccMsg);
          this.saveBtnBool=true;
          this.previewDefBool=true;

          $.ajax({
            url: this.baseUrl+"api/hlw/campaign/survey/qutnTopItem/delete",
            type: "post",
            data: JSON.stringify(objQutn),
            dataType: "json",
            contentType: "application/json",
            headers : {
              Authorization:this.mixin_getToken()
            },
            success: function(data) {
              if (!data) {
                return alertErr;
              }else{
                return defFn;
              }
            },
            error: function(errorThrown) {
              return alertErr;
            }
          });
        }

        this.fnSurveyItemDivDelete(index);
      },

      fnSurveyItemDivDelete(index) {
        this.TEMPLATE_LIST.splice(index, 1);

        this.ORDER_SEQ_ARR.splice(index, 1);
        this.TYPE_ARR.splice(index, 1);
        this.QUES_ONE.splice(index, 1);
        this.QUES_TWO.splice(index, 1);
        this.MULTI_TYPE_LIMIT.splice(index, 1);
        this.MULTI_TYPE_LIMIT_BOOL.splice(index, 1);
        this.IMG_INPUT_MODEL.splice(index, 1);
        this.IMG_SRC_MODEL.splice(index, 1);
        this.ANSWER_PA_TEXT.splice(index, 1);

        this.QUTN_ITEM_CODE.splice(index, 1);
        this.QUTN_ITEM_DETL_CODE_ARR.splice(index, 1);

        setTimeout(() => this.fnImgDivRender(), 500);
      },

      fnTopSaveTempConf() {
        this.common_confirm(this.alertMsg.uptMsg, this.fnTopSaveTemplatte, null, null, null, 'chk');
      },

      async fnTopSaveTemplatte() {

        let surveyTitle=this.SURVEY_TITLE;
        if(this.SURVEY_TITLE.includes('\n')) {
          surveyTitle=this.SURVEY_TITLE.replace(/\n/ig, '<br/>');
        }

        let surveyCont=this.SURVEY_CONT
        if(this.SURVEY_CONT.includes('\n')) {
          surveyCont=this.SURVEY_CONT.replace(/\n/ig, '<br/>');
        }

        let objQutn={
          QUTN_ID:this.QUTN_ID,
          QUTN_NAME:surveyTitle,
          QUTN_CONT:surveyCont,
          USE_YN:this.USE_YN,
          USER_ID:this.user_id,
        };

        const alertErr=this.common_alert(this.alertMsg.error, "error");
        const defFn=this.common_alert(this.alertMsg.saveSuccMsg);
        this.saveBtnBool=true;
        this.previewDefBool=true;

        $.ajax({
          url: this.baseUrl+"api/hlw/campaign/survey/qutnTopItem/regist",
          type: "post",
          data: JSON.stringify(objQutn),
          dataType: "json",
          contentType: "application/json",
          headers : {
            Authorization:this.mixin_getToken()
          },
          success: function(data) {
            if (!data) {
              return alertErr;
            }else{
              return defFn;
            }
          },
          error: function(errorThrown) {
            return alertErr;
          }
        });
      },

      fnSaveTemplatte(item) {
        const index=this.TEMPLATE_LIST.indexOf(item);

        if(!this.mixin_isEmpty(this.TYPE_ARR[index])) {

          if(this.TYPE_ARR[index]==="QUTN_ITEM_TYPE_CD_10"
            ||this.TYPE_ARR[index]==="QUTN_ITEM_TYPE_CD_11"
              ||this.TYPE_ARR[index]==='QUTN_ITEM_TYPE_CD_12'
          ){
            if(this.mixin_isEmpty(this.ANSWER_PA_TEXT[index].ANSWER_CH_TEXT)) {
              this.common_alert(this.alertMsg.ansChkMsg, 'chk');
              return;
            }
          }

          //문항 개별 저장
          this.common_confirm(this.alertMsg.qutnItemSaveChkMsg, this.fnQutnItemSave, index, null, null, 'chk');
        }else{
          this.common_alert(this.alertMsg.typeSelMsg, 'chk');
        }

      },

      async fnQutnItemSave(index) {
        
        let quesTwo=this.QUES_TWO[index];
        if(!this.mixin_isEmpty(quesTwo) && quesTwo.includes('\n')) {
          quesTwo=quesTwo.replace(/\n/ig, '<br/>');
        }

        let obj={
          QUTN_ID:this.QUTN_ID,
          USER_ID:this.user_id,
          ORDER_SEQ:this.ORDER_SEQ_ARR[index],
          QUTN_ITEM_TYPE_CD:this.TYPE_ARR[index], //설문 항목 유형 코드
          QUTN_ITEM_NAME:this.QUES_ONE[index], //유형별 질문1
          QUTN_ITEM_CONT:quesTwo, //유형별 질문2
          QUTN_ITEM_CODE:this.QUTN_ITEM_CODE[index],
          QUTN_ITEM_DETL_CODE:this.QUTN_ITEM_DETL_CODE_ARR[index],
        };

        if(obj.QUTN_ITEM_TYPE_CD==="QUTN_ITEM_TYPE_CD_10"
          ||obj.QUTN_ITEM_TYPE_CD==="QUTN_ITEM_TYPE_CD_11"
            ||obj.QUTN_ITEM_TYPE_CD==='QUTN_ITEM_TYPE_CD_12'
        ){
          if(!this.mixin_isEmpty(this.ANSWER_PA_TEXT[index].ANSWER_CH_TEXT)) {
            obj['QUTN_ITEM_DETL_CONT']=this.ANSWER_PA_TEXT[index].ANSWER_CH_TEXT;
          }

          if(obj.QUTN_ITEM_TYPE_CD==="QUTN_ITEM_TYPE_CD_11") {
            if(!this.mixin_isEmpty(this.MULTI_TYPE_LIMIT[index])) {
              obj['MULTI_TYPE_LIMIT']=this.MULTI_TYPE_LIMIT[index];
            }
          }
        }

        const alertErr=this.common_alert(this.alertMsg.error, "error");
        const defFn=this.common_alert(this.alertMsg.saveSuccMsg);
        let qutnItemCdArr=this.QUTN_ITEM_CODE;
        let qutnItemDetaCdArr=this.QUTN_ITEM_DETL_CODE_ARR;

        this.saveBtnBool=true;
        this.previewDefBool=true;

        let modiBoolArr=this.modiBoolArr;

        $.ajax({
          url: this.baseUrl+"api/hlw/campaign/survey/qutnItem/regist",
          type: "post",
          data: JSON.stringify(obj),
          dataType: "json",
          contentType: "application/json",
          headers : {
            Authorization:this.mixin_getToken()
          },
          success: function(data) {
            if (data!='' && data!=null) {
              qutnItemCdArr[index]=data['QUTN_ITEM_CODE'];
              if(data['QUTN_ITEM_DETL_CODE_ARR']!=null && data['QUTN_ITEM_DETL_CODE_ARR']!='') {
                qutnItemDetaCdArr[index]=data['QUTN_ITEM_DETL_CODE_ARR'];
              }

              return defFn;
            }else{
              return alertErr;
            }
          },
          error: function(errorThrown) {
            return alertErr;
          }
        });
      },

      fnImgDivRender() {
        this.TEMPLATE_LIST.forEach((element, i) => {
          const typeVal=document.querySelector('#type_'+i).value;
          this.fnTextOnOff(typeVal, i);
          document.querySelector('#surveyDiv_'+i).style.display='block';
        });

      },

      fnAnswerAdd(item, idx) {
        const $id=document.getElementById('type_'+idx);
        if($id.value){
          let ans=this.TEMPLATE_LIST[idx].ANSWER_LIST;
          ans.push(this.ANSWER_PA_TEXT[idx].ANSWER_CH_TEXT[ans.length]);

          const typeByTextBtnEle=document.getElementById('typeByTextBtn_'+idx);
          typeByTextBtnEle.style.display='block';

          if(!this.mixin_isEmpty(this.QUTN_ID)) {
            this.QUTN_ITEM_DETL_CODE_ARR[idx].push("");
          }
          
        }else{
          this.common_alert(this.alertMsg.typeSelMsg, 'chk')
        }
      },

      fnAnswerRemove(idx, chIdx) {
        let params={
          idx:idx
          , chIdx:chIdx
        };
        this.common_confirm(this.alertMsg.answDelChkMsg, this.fnAnswerRemoveAf, params, null, null, 'chk');
      },

      fnAnswerRemoveAf(params) {
        let idx=params.idx;
        let chIdx=params.chIdx;
        
        this.ANSWER_PA_TEXT[idx].ANSWER_CH_TEXT.splice(chIdx, 1);
        this.TEMPLATE_LIST[idx].ANSWER_LIST.splice(chIdx, 1);
        this.QUTN_ITEM_DETL_CODE_ARR[idx].splice(chIdx, 1);
      },

      /**
      * XSS Encode
      */
      encode(strText) {
        if (strText === "" || strText == null) {
          return strText;
        }

        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        strText = strText.replaceAll("\r", "<br/>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");

        return strText;
      },

      /**
      * XSS Decode
      */
      decode(strText) {
        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }
        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }
        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br/>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },

    },

    computed: {
      cpQutnChkBool() {
        if(this.mixin_isEmpty(this.QUTN_ID)) {
          return true;
        }else{
          return false;
        }
      },

    },
  };
</script>